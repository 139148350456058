<script setup lang="ts">
import type { ResourcePreview } from '~/integrations/resources/types';
import { NuxtLinkLocale } from '#components';

const props = withDefaults(
  defineProps<{
    collectionId?: string;
    isElongated?: boolean;
    queryId?: string;
    resourcePreview: ResourcePreview;
    suppressTracking?: boolean;
  }>(),
  {
    collectionId: undefined,
    isElongated: false,
    queryId: undefined,
    suppressTracking: false,
  }
);

const { locale, t } = useI18n();

const formatDate = useFormatDate();
const { trackResourcePreviewClick } = useAlgoliaInsightsTracking();

const routeLocation = computed(() => {
  const routeStart = props.resourcePreview.resourceType.slug === 'collection' ? 'collections' : 'resources';

  return {
    path: `/${routeStart}/${props.resourcePreview.idSlug}/${props.resourcePreview.slug}`,
    query: {
      queryId: props.queryId,
      collectionId: props.collectionId,
    },
  };
});

const localeCode = computed(() => {
  return locale.value as 'en' | 'es' | 'fr';
});

const formattedDate = computed(() => formatDate(props.resourcePreview.publicationDate));

function trackClick() {
  if (props.suppressTracking) {
    return;
  }

  trackResourcePreviewClick({
    id: props.resourcePreview.id,
    type: props.resourcePreview.resourceType.slug,
  });
}
</script>

<template>
  <div class="flex py-6 xs:gap-4 sm:gap-8">
    <!-- FIXME: Pass in Sanity image  caption -->
    <NuxtLinkLocale :to="routeLocation" class="hover:no-underline" @click="() => trackClick()">
      <ResourcePreviewImage
        :duration-in-minutes="resourcePreview.durationInMinutes"
        :image="resourcePreview.thumbnailImageObject"
        :resource-title="resourcePreview.title"
        :resource-type="resourcePreview.resourceType"
        show-duration
      />
    </NuxtLinkLocale>

    <div class="flex flex-grow flex-col">
      <div class="flex items-center">
        <Chip :fill="resourcePreview.resourceType.slug === 'collection' ? 'outline' : 'solid'">
          {{ resourcePreview.resourceType.name[localeCode] }}
        </Chip>
        <ClientOnly>
          <p class="details-1 mb-0 ml-3 sm:ml-4">{{ formattedDate }}</p>
        </ClientOnly>

        <!-- TODO: Display "New" label as appropriate, once functionality is enabled -->
        <p v-if="resourcePreview.isNew" class="new-item mb-0">{{ t('search.results.card.new') }}</p>
      </div>

      <NuxtLinkLocale :to="routeLocation" @click="() => trackClick()">
        <h3 class="h3 hover:underline">{{ resourcePreview.title }}</h3>
      </NuxtLinkLocale>

      <p>{{ resourcePreview.excerpt }}</p>
      <div v-if="!isElongated" class="flex gap-3 pt-2">
        <!-- TODO: Secondary button should direct-open OR toggle ResourceActionModal if options exist -->
        <!-- TODO: Replace buttons with one currently in ResourceActionModal -->
        <Button :as="NuxtLinkLocale" :to="routeLocation" @click="() => trackClick()">
          {{ t('common.resourceCard.button.details') }}
        </Button>
      </div>
    </div>
    <!-- Action panel width hand-sized based on widest button (Download) -->
    <div
      v-if="isElongated"
      class="flex min-w-[10.5rem] flex-shrink-0 flex-grow-0 flex-col justify-between gap-y-3 border-l pl-8"
    >
      <!-- Action buttons -->
      <div class="flex flex-col gap-y-3">
        <!-- TODO: Secondary button should direct-open OR toggle ResourceActionModal if options exist -->
        <!-- TODO: Replace buttons with one currently in ResourceActionModal -->
        <Button :as="NuxtLinkLocale" :to="routeLocation" @click="() => trackClick()">
          {{ t('common.resourceCard.button.details') }}
        </Button>
      </div>

      <!-- Languages -->
      <div v-if="resourcePreview.localeNames.length" class="flex flex-col">
        <h3 class="h2--label mb-1">{{ t('resource.details.languages') }}</h3>
        <p v-for="localeName in resourcePreview.localeNames" :key="localeName" class="mb-0">
          {{ localeName }}
        </p>
      </div>
    </div>
  </div>
</template>
